@tailwind base;
@tailwind components;
@tailwind utilities;
.App {
  width: 100%;
  font-family: "Raleway", "Roboto";
  color: #333;
}
.App .container {
  margin: 0 auto;
  max-width: 1280px !important;
}

#wrapper-news-template img {
  max-width: 100%;
  object-fit: contain;
}

#wrapper-news-template .italic {
  font-style: italic;
}

#wrapper-news-template p {
  text-align: justify;
}

@keyframes spin {
  from {
    transform: rotate(0deg); /* Điểm bắt đầu */
  }
  to {
    transform: rotate(360deg); /* Xoay đủ 360 độ */
  }
}
